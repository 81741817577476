<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  .form-check-input {
    margin-bottom: 5px;
  }

  .form-table th,
  .form-table td {
    padding: 0;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import ScoreHeader from "./score-header";
  import {
    editCjk,
    getCjkInfo,
    clearCjkTime
  } from "@/api/score/scoreKu.js"
  /**
   * 成绩库导入--发布时间
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      ScoreHeader,


    },
    data() {
      return {
        title: "成绩库导入 ",
        title2: "",
        items: [{
            text: "用户首页",
            href: '/admin'
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        sid: "",
        fhfw: [],
        otherCon: [],
        addForm: {},
        pickerOptions_start1: "",
        pickerOptions_end1: "",
        pickerOptions_start: "",
        pickerOptions_end: "",
        condibox: ["N", "le0", "eq0", "eq-1"]
      };
    },
    methods: {
      // 获取详情
      getDeatails(sid) {
        getCjkInfo(sid).then(res => {
          if (res.status) {
            this.addForm = res.data

            this.title2 = res.data.ksmc ? res.data.ksmc : ""
            let kskms = res.data.kskms ? res.data.kskms : "" //考试科目数
            // 初始化
            if (kskms) {
              this.fhfw = [];
              this.otherCon = [];
              for (let i = 0; i < kskms; i++) {
                this.fhfw.push([])
                this.otherCon.push("")
              }
            }

            let newFhfw = this.addForm.fhcjfw
            let list = []
            if (newFhfw) {
              list = newFhfw.split(',')
              list.forEach((v, index) => {
                let list2 = v.split("_")
                list2.forEach(x => {
                  // 判断是否是页面数据
                  if (this.condibox.filter(val => {
                      return val == x
                    })[0]) {
                    this.fhfw[index].push(x)
                  } else {
                    let inputVal = x.slice(2)
                    if (inputVal) {
                      this.otherCon[index] = this.otherCon[index] ? this.otherCon[index] + x.slice(2) +
                        "," :
                        x.slice(2) + ","
                    }

                  }
                })

              })

            }
          }
        })
      },
      // 修改成绩库
      editItem() {
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        let kskms = formDate.kskms
        let fhcjfw = []
        for (let i = 0; i < 9; i++) {
          fhcjfw.push(null)
        }
        let list = []
        this.fhfw.forEach((item, i) => {
          let str = item.join("_")
          if (this.otherCon[i]) {
            str += "_eq" + this.otherCon[i].split(",").join("_eq")
          }
          list.push(str)
        })

        fhcjfw.splice(0, kskms, ...list)

        formDate.fhcjfw = fhcjfw.toString()

        editCjk({
          ...formDate,
          sid: this.sid
        }).then(res => {
          if (res.status) {
            this.$notify({
              type: 'success',
              message: res.message,
              duration: 3000,
            })
            this.$refs.header.getUrl('/admin/scoreImportStyle')

          }
        })
      },
      limitDate() {
        let _this = this
        let date_start = new Date(_this.addForm.kfrq).getTime();
        let date_end = new Date(_this.addForm.jsrq).getTime();
        _this.pickerOptions_start1 = {
          disabledDate: (time) => {
            if (date_end != "") {
              return time.getTime() > date_end;
            } else {
              return time.getTime() > Date.now();
            }
          },
        };
        _this.pickerOptions_end1 = {
          disabledDate: (time) => {
            return time.getTime() < date_start;
          },

        }
      },
      changeDate() {
        let _this = this
        let date_start = new Date(_this.addForm.fhkssj).getTime();
        let date_end = new Date(_this.addForm.fhjssj).getTime();
        _this.pickerOptions_start = {
          disabledDate: (time) => {
            if (date_end != "") {
              return time.getTime() > date_end;
            } else {
              return time.getTime() > Date.now();
            }
          },
        };
        _this.pickerOptions_end = {
          disabledDate: (time) => {
            return time.getTime() < date_start;
          },

        }
      },
      // 勾了不限制
      nolimit(e) {
        let checked = e.target.checked
        let index = e.target.dataset.index
        if (checked) {
          this.fhfw[index] = ["N"]
          this.otherCon = []
        }
      },
      // 其他的限制
      limit(e) {
        let checked = e.target.checked
        let index = e.target.dataset.index
        if (checked) {
          this.fhfw[index] = this.fhfw[index].filter(v => {
            return v != 'N'
          })
        }
      },
      // input限制
      limit2(e) {
        let index = e.target.dataset.index
        if (this.fhfw[index][0] == "N") {
          console.log(this.fhfw[index][0]);

        }


      },
      //清空时间
      clearTime(text) {
        clearCjkTime(this.sid, text).then(res => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "当前时间清空成功！"
            })
            this.$nextTick(()=>{
              this.getDeatails(this.sid)
            })

          }
        })
      }
    },
    mounted() {
      if (this.sid) {
        this.getDeatails(this.sid)
      }
    },
    created() {
      this.sid = this.$route.query["sid"] ? this.$route.query["sid"] : "";

    },
    watch: {
      fhfw: {
        handler(val) {
          // console.log(val)
        }
      },
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="title2" :items="items" />
    <ScoreHeader ref="header" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px;">
          <div class="card-body">
            <div class="outerCotent mt-3 ">
              <input type="hidden" class=" msgValInput w-30" value="" name="sid">

              <div class="msgContent flexList fs-xs">
                <div class="flexList w-100 msgLabel ">
                  <div class="msgName ">开放查成绩时间</div>
                  <div class="msgVal selectShow  flexList">
                    <el-date-picker v-model="addForm.kfrq" type="datetime" class=" h30 w-30" size="small"
                      placeholder="选择日期时间 " value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions_start1"
                                    @input="limitDate">
                    </el-date-picker>
                    <el-button @click="clearTime('kfrq')" type="info" size="mini" class="ml-2">清空时间</el-button>
                  </div>
                </div>
                <div class="flexList w-100 msgLabel ">
                  <div class="msgName ">结束查成绩时间</div>
                  <div class="msgVal selectShow  flexList">
                    <el-date-picker v-model="addForm.jsrq" type="datetime" class=" h30 w-30" size="small"
                                    placeholder="选择日期时间 " value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions_end1"
                                    @input="limitDate">
                    </el-date-picker>
                    <el-button @click="clearTime('jsrq')" type="info" size="mini" class="ml-2">清空时间</el-button>
                  </div>
                </div>

                <div class="flexList w-100 msgLabel ">
                  <div class="msgName ">是否允许未登录查成绩</div>
                  <div class="msgVal selectShow  flexList w-33">
                    <div class="frame-wrap">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="val1" value="1" v-model="addForm.sfyxwdl">
                        <label class="custom-control-label" style="line-height:19px" for="val1">允许</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input " id="val2" value="0"
                               v-model="addForm.sfyxwdl">
                        <label class="custom-control-label" style="line-height:19px" for="val2">不允许</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flexList w-100 msgLabel ">
                  <div class="msgName ">是否允许复核成绩</div>
                  <div class="msgVal selectShow  flexList w-33">
                    <div class="frame-wrap">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="value1" value="1" v-model="addForm.sfyxfh">
                        <label class="custom-control-label" style="line-height:19px" for="value1">允许</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input " id="value2" value="0"
                          v-model="addForm.sfyxfh">
                        <label class="custom-control-label" style="line-height:19px" for="value2">不允许</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="addForm.sfyxfh==1" class="w-100">
                  <table class="w-100 msgLabel form-table">
                    <tr>
                      <th style="width: 13%;">
                        <div class="msgName w-100" style="font-weight: normal;">复核成绩范围</div>
                      </th>
                      <td>
                        <div class=" msgVal">
                          <div class="d-flex justify-content-between w-50 h-100" v-for="(item,index) in addForm.kskms"
                            :key="index">
                            <div>科目{{item}}：</div>
                            <div class="form-check flexList mr-3">
                              <input class="form-check-input" type="checkbox" value="N" :id="'fhcjfw1'+index"
                                v-model="fhfw[index]" @change="nolimit" :data-index="index" />
                              <label class="form-check-label" :for="'fhcjfw1'+index">不限制</label>
                            </div>
                            <div class="form-check flexList mr-3"><input type="checkbox" value="le0"
                                :id="'fhcjfw2'+index" @change="limit" :data-index="index" class="form-check-input"
                                v-model="fhfw[index]"><label :for="'fhcjfw2'+index" class="form-check-label">小于等于0分
                              </label>
                            </div>
                            <div class="form-check flexList mr-3"><input type="checkbox" value="eq0"
                                :id="'fhcjfw3'+index" @change="limit" :data-index="index" class="form-check-input"
                                v-model="fhfw[index]"><label :for="'fhcjfw3'+index" class="form-check-label">0分
                              </label>
                            </div>
                            <div class="form-check flexList mr-3"><input type="checkbox" value="eq-1"
                                :id="'fhcjfw4'+index" @change="limit" :data-index="index" class="form-check-input"
                                v-model="fhfw[index]"><label :for="'fhcjfw4'+index" class="form-check-label">-1分
                              </label>
                            </div>
                            <div class="flexList w-30"><input type="text" v-model="otherCon[index]"
                                class="form-control h30" placeholder="其他,多个以英文逗号','隔开" @focus="limit2"
                                :data-index="index">
                            </div>

                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="flexList w-100 msgLabel">
                  <div class="msgName ">复核成绩开始时间</div>
                  <div class="msgVal  selectShow flexList">
                    <el-date-picker v-model="addForm.fhkssj" type="datetime" class="  h30 w-30" size="small"
                      placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions_start"
                      @input="changeDate">
                    </el-date-picker>
                    <el-button @click="clearTime('fhkssj')" type="info" size="mini" class="ml-2">清空时间</el-button>
                  </div>
                </div>
                <div class="flexList w-100 msgLabel">
                  <div class="msgName ">复核成绩结束时间</div>
                  <div class="msgVal  selectShow flexList">
                    <el-date-picker v-model="addForm.fhjssj" type="datetime" class="  h30 w-30" size="small"
                      placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions_end"
                      @input="changeDate">
                    </el-date-picker>
                    <el-button @click="clearTime('fhjssj')" type="info" size="mini" class="ml-2">清空时间</el-button>

                  </div>
                </div>
                <div class="flexList w-100 msgLabel" style="height: 60px;">
                  <div class="msgName"></div>
                  <div class="msgVal flexList">
                    <button class="btn btn-info h35 mr-2 sub-btn" @click="editItem">确定并进入下一步</button>
                    <a href="javascript:history.back()"><button class="btn btn-secondary h35 mr-2 ">返回上一步</button></a>
                  </div>
                </div>
              </div>





            </div>

          </div>



        </div>
      </div>
    </div>


    </div>
    <!-- 弹窗开始 -->

    <!-- 弹窗结束 -->
  </Layout>
</template>
